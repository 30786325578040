/* ==========================================================================
	Footer
	========================================================================== */

.panel-ref-footer {
	background-color: white;
}

.footer {
	text-align: center;
	background: darken($brand-second, 15%);
	background: radial-gradient(circle, darken($brand-second, 10%) 0%, darken($brand-second, 20%) 100%);
	font-size: $font-size-base;
	color: white;
	min-height: 25rem;
	a,h1,h2,h3 {
		color: $brand-primary;
	}
	a:hover {
		color: lighten($brand-primary, 15%);
	}

	@media(min-width: $screen-sm-min) {
		text-align: left;
	}
}

.logo-footer {
	max-height: 11rem;
}

.footer__address {
	line-height: 2;
}

.footer__list-horaires {
	font-size: $font-size-small;

	>li {
		padding-bottom: .5rem;
	}
}

.list-footer {

	>li {
		padding-bottom: 1rem;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 1rem;
		margin-top: 1rem;
		border-top: solid 1px $hr-border;
	}
}

.g-play__img {
	width: 9rem;
	margin-top: 1rem;
}
